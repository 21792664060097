<template>
 <div :class="windowClass">
   <v-container>
     <v-toolbar flat>
       <div class="text-h4">BETS</div>
       <v-spacer/>
     
     </v-toolbar>
     <v-divider></v-divider>
    <v-row dense class="mt-10">
       <v-col cols="12">
         <v-col cols="12">
     <v-toolbar flat dense>
       <v-spacer/>
        <v-spacer/>
       <v-autocomplete 
        label="Select Event"
        v-model="event"
        :items="events"
        dense
        outlined
        item-text="name"
        return-object
        @change="getFights()"
       />
       
     </v-toolbar>
      </v-col>
     <v-toolbar class="mb-2" dense>EVENT NAME: {{event.name}}<v-spacer></v-spacer>DATE: {{event.event_date}}<v-spacer></v-spacer>
        <span style="width:200px;">
      
    </span>
    </v-toolbar>
       <table class="va-table md-font">
                <tr>
                  <td style="background: #313131; color: #fff" class="pa-1">POS</td>
                  <td style="background: #313131; color: #fff" class="pa-1">FIGHT #</td>
                  <td style="background: #313131; color: #fff" class="pa-1">TRANS ID</td>
                  <td style="background: #313131; color: #fff" class="pa-1">CODE</td>
                  <td style="background: #313131; color: #fff" class="pa-1">BET ON</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">AMOUNT</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">WIN</td>
                  <td style="background: #313131; color: #fff" class="pa-1 text-right">STATUS</td>
                  <!-- <td style="background: #313131; color: #fff" class="pa-1 text-right">PROFIT </td> -->
                </tr>
                <tr v-for="(item,index) in bets" :key="index">
                    <td class="border_bot">
                       {{ item.agent }}
                    </td>
                    <td class="border_bot">{{item.fight_no}}</td>
                    <td class="border_bot">{{item.id}}</td>
                    <td class="border_bot">{{item.code}}</td>
                    <td class="border_bot">{{item.bet_to}}</td>
                    <td class="border_bot text-right">{{$money(item.amount)}}</td>
                    <td class="border_bot text-right">{{$money(item.win)}}</td>
                    <td class="border_bot text-right">
                      <div :class="STATUS_COLOR(item.status)">{{item.status}}</div>
                      <v-chip dense color="success" small v-if="item.status == 'Claimed'">Claimed by: {{item.claimed_by}}</v-chip>
                    </td>
                  
                 
                </tr>
               
           </table>
       </v-col>
    </v-row>
    </v-container>
      
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        formform: false,
        state: 'new',
        bets:{},
        event_id:0,
        fights: [],
        events: [],
        event: {},
        search:'',
        headers: [
            { text: 'Fight No.', value:"event_date"},
            { text: 'Meron', value:"meron", align:"left"},
            { text: 'Wala', value:"wala", align:"left"},
             { text: 'Meron Gross', value:"meron", align:"left"},
            { text: 'Wala Gross', value:"wala", align:"left"},
            { text: 'Status', value:"status"},
            { text: 'Result', value:"result", align: 'right'},
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
       // this.getItems()
        this.getEvents()
       // this.GET_BREEDERS()
        //this.GET_OWNERS()
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      TOTALMERON(){
        var total = this.fights.reduce((res,item)=>res+=item.meron_collection,0)
        return total
      },
      TOTALWALA(){
        var total = this.fights.reduce((res,item)=>res+=item.wala_collection,0)
        return total
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
      commission(amount){
        let percentage = this.$IsNum(this.event.venue)/100
        return amount * percentage
      },
        profit(amount){
        return amount * .06
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.fight = data.fight
              this.Save()
          } else {
             this.getItems()
           
          }
      },
      formResEvent(){
        this.formform = false
        setTimeout(()=>{
                 this.getItems()
            }, 3000)
      },
       getFights(){
          this.$http.post("event/unclaim_bets", {name: this.event.name}).then(response => {
            response.data.items !=null? this.bets = response.data.items:this.bets=[] 
           
        }).catch(e => {
          console.log(e)
        })
      },
       getEvents(){
           this.$http.get("event/list").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
      },
      
    }
  }
</script>